<!-- PMTaskManagement -->
<template>
  <div class="PMTaskManagement w center_all">
    <div class="center_2">
      <h3>任务管理</h3>
      <div class="pm-options">
        <div class="options-item">
          <span>选择期刊：</span>
          <el-select v-model="chooseMagId" clearable filterable placeholder="请选择">
            <el-option v-for="item in magList" :key="item.magId" :label="item.magName" :value="item.magId" />
          </el-select>
        </div>
        <div class="options-item">
          <span>状态：</span>
          <el-select v-model="chooseStatus" clearable filterable placeholder="请选择">
            <el-option v-for="item in statusList" :key="item.status" :label="item.name" :value="item.status" />
          </el-select>
        </div>
        <div class="options-item">
          <span>销售：</span>
          <el-select v-model="chooseSale" clearable filterable placeholder="请选择">
            <el-option v-for="item in saleList" :key="item.id" :label="item.realName" :value="item.id" />
          </el-select>
        </div>
        <el-button @click="getPMTaskList">查询</el-button>
      </div>
      <div class="pm-options">
        <div class="options-item">
          <span>服务情况：</span>
          <el-select v-model="chooseServiceStatus" clearable filterable placeholder="请选择">
            <el-option v-for="item in serviceStatusList" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </div>
        <div class="options-item">
          <span>运营负责人：</span>
          <el-select v-model="chooseOperateMemberName" clearable filterable placeholder="请选择">
            <el-option v-for="item in operateMemberNameList" :key="item" :label="item" :value="item" />
          </el-select>
        </div>
      </div>
      <div class="pm-taskList">
        <SimpleTable
          showOperate
          showBtn1
          showBtn2
          btn2Text="查看信息"
          :tableData="tableData"
          :columnData="tableColumn"
          :initObj="tablePageObj"
          @onClickBtn1="handleBtn1Click"
          @onClickBtn2="handleBtn2Click"
          @dateTimePickerChange="editTaskList"
          @handleItemSelectChange="handleItemSelectChange"
          @onChangeInputBlur="handleInputBlur"
          @onChangeSort="
            (prop, order) => {
              handleChangeSort(prop, order)
            }
          "
          @onChangePageData="
            (pageObj) => {
              getPMTaskList(pageObj)
            }
          "
        ></SimpleTable>
      </div>
    </div>
  </div>
</template>

<script>
import { GetPMMagList, GetSaleMagList, GetMagList, NewEditInfo, RevocationNotice, EditFlow, GetOperateMemberNames } from '@/api/roleManagement'
import SimpleTable from '@/components/SimpleTable'
// import { FormatDateTime } from '@/assets/js/util'
export default {
  name: 'PMTaskManagement',
  data() {
    return {
      chooseMagId: '',
      chooseStatus: '',
      chooseSale: '',
      chooseServiceStatus: '',
      chooseOperateMemberName: '',
      magList: [],
      statusList: [
        {
          status: 1,
          name: '待设计'
        },
        {
          status: 2,
          name: '待配置'
        },
        {
          status: 3,
          name: '已完成'
        }
      ],
      saleList: [],
      serviceStatusList: [
        {
          value: 0,
          label: '全部'
        },
        {
          value: 1,
          label: '邮件'
        },
        {
          value: 2,
          label: '微信'
        },
        {
          value: 9,
          label: '暂停'
        }
      ],
      operateMemberNameList: [],
      tableData: [],
      tableColumn: [
        {
          title: '期刊名称',
          prop: 'magName',
          notSortable: true,
          width: '150px'
        },
        {
          title: '销售',
          prop: 'createMemberName',
          notSortable: true,
          width: '60px',
          edit: true
        },
        {
          title: '申请时间',
          prop: 'resultTime',
          notSortable: 'custom',
          width: '100px'
        },
        {
          title: '计划开通时间',
          prop: 'planFinishTime',
          notSortable: true,
          dateTimePicker: true,
          width: '120px'
        },
        {
          title: '实际开通时间',
          prop: 'finishTime',
          notSortable: true,
          width: '100px'
        },
        {
          title: '状态',
          prop: 'node',
          notSortable: true,
          width: '105px',
          select: true,
          selectOptions: [
            {
              value: 0,
              label: '待提交'
            },
            {
              value: 1,
              label: '待设计'
            },
            {
              value: 2,
              label: '待配置'
            },
            {
              value: 3,
              label: '已完成'
            },
            {
              value: 4,
              label: '正式推送'
            }
          ]
        },
        {
          title: '服务情况',
          prop: 'serviceStatus',
          notSortable: true,
          width: '100px',
          select: true,
          selectOptions: [
            {
              value: 0,
              label: '全部'
            },
            {
              value: 1,
              label: '邮件'
            },
            {
              value: 2,
              label: '微信'
            },
            {
              value: 9,
              label: '暂停'
            }
          ]
        },
        {
          title: '运营负责人',
          prop: 'operateMemberName',
          notSortable: true,
          width: '100px',
          edit: true
        },
        {
          title: '备注',
          prop: 'note',
          notSortable: true,
          input: true,
          width: '130px'
        }
      ],
      tablePageObj: {
        page: 1,
        size: 20,
        sort: 'createTime,desc'
      }
    }
  },
  props: {},
  components: { SimpleTable },
  computed: {},
  watch: {
    chooseMagId() {
      this.getPMTaskList()
    },
    chooseStatus() {
      this.getPMTaskList()
    },
    chooseSale() {
      this.getPMTaskList()
    },
    chooseServiceStatus() {
      this.getPMTaskList()
    },
    chooseOperateMemberName() {
      this.getPMTaskList()
    }
  },
  mounted() {
    this.getPMMagList()
    this.getSaleMagList()
    this.getPMTaskList()
    this.getOperateMemberNames()
  },
  methods: {
    // 期刊列表
    getPMMagList() {
      let params = {}
      GetPMMagList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.magList = data
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 销售列表
    getSaleMagList() {
      let params = {}
      GetSaleMagList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.saleList = data
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    getOperateMemberNames() {
      let params = {}
      GetOperateMemberNames(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.operateMemberNameList = data
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 任务列表
    getPMTaskList(pageParams = {}) {
      let { page = 1, size = 20 } = pageParams
      let params = {
        magId: this.chooseMagId,
        node: this.chooseStatus,
        memberId: this.chooseSale,
        serviceStatus: this.chooseServiceStatus,
        operateMemberName: this.chooseOperateMemberName,
        size,
        page: page - 1,
        sort: this.tablePageObj.sort
      }
      GetMagList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          let { page = 1, size = 20 } = pageParams
          this.tablePageObj = {
            ...this.tablePageObj,
            page,
            size,
            total: data.totalElements
          }
          this.tableData = data.content
          this.tableData.map((item) => {
            // node流程节点 0:待提交 1:待设计 2:待配置 3:已完成
            if (item.node == 1) {
              item.status = '待设计'
              item.btn1Text = ' '
            } else if (item.node == 2) {
              item.status = '待配置'
              item.btn1Text = '已完成通知销售'
              item.btn1Class = true
            } else if (item.node == 3) {
              item.status = '已完成'
              item.btn1Text = ' '
              item.notShowDateTimePicker = true
            } else {
              item.status = '待提交'
              item.btn1Text = ' '
            }
            item.createTime = item.createTime && item.createTime.indexOf('T') != -1 ? item.createTime.split('T')[0] : item.createTime
            item.finishTime = item.finishTime && item.finishTime.indexOf('T') != -1 ? item.finishTime.split('T')[0] : item.finishTime
            item.resultTime = item.finishTime || item.createTime
            item.planFinishTime = item.planFinishTime && item.planFinishTime.indexOf('T') != -1 ? item.planFinishTime.split('T')[0] : item.planFinishTime
          })
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    handleBtn1Click(item) {
      // node流程节点 0:待提交 1:待设计 2:待配置 3:已完成
      if (item.node == 2) {
        // 待配置
        this.editTaskList(item)
        // item.status = '待配置'
      } else if (item.node == 3) {
        // 已完成
        // this.revocationNotice(item)
      }
    },
    handleBtn2Click(item) {
      sessionStorage.setItem('pmItem', JSON.stringify(item))
      let routeData = this.$router.resolve({
        name: 'newApply',
        query: { fromPage: 'pm' }
      })
      window.open(routeData.href, '_blank')
    },
    // 排序的改变了
    handleChangeSort(prop, order) {
      let sortValue = ''
      if (order && order !== '') {
        if (order === 'ascending') {
          sortValue = prop + ',asc'
        } else {
          sortValue = prop + ',desc'
        }
      }
      this.tablePageObj.sort = sortValue
      this.getPMTaskList()
    },
    // 编辑任务列表
    editTaskList(item) {
      let params = { ...item }
      NewEditInfo(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.$message.success('操作成功')
          this.getPMTaskList()
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 撤销通知
    revocationNotice(item) {
      let params = {
        id: item.id
      }
      RevocationNotice(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.$message.success('撤销成功')
          this.getPMTaskList(this.tablePageObj)
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 服务状态和备注发生改变
    handleItemSelectChange(val, item, type, prop) {
      let params = {}
      if (type === 'select' && prop === 'serviceStatus') {
        params = {
          serviceStatus: val,
          note: item.note,
          node: item.node
        }
      } else if (type === 'select' && prop === 'node') {
        params = {
          serviceStatus: item.serviceStatus,
          note: item.note,
          node: val
        }
      } else if (type === 'input') {
        params = {
          serviceStatus: item.serviceStatus,
          note: val,
          node: item.node
        }
      }
      EditFlow({ ...params, id: item.id }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.$message.success('操作成功')
          this.getPMTaskList(this.tablePageObj)
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    //input框时失焦事件
    handleInputBlur(val, prop, event, index, content, id) {
      console.log(val, prop, event, index, content, id)
      let params = {
        [prop]: content,
        id: id
      }
      EditFlow(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.$message.success('操作成功')
          this.getPMTaskList(this.tablePageObj)
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.PMTaskManagement {
  .center_2 {
    min-height: 400px;
    border-radius: 8px;
    padding: 40px;
    h3 {
      text-align: left;
    }
    .pm-options {
      display: flex;
      margin-top: 30px;
      .options-item {
        margin-right: 20px;
      }
    }
    .pm-taskList {
      margin-top: 30px;
    }
  }
}
</style>
